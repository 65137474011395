// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-pytaj-o-co-chcesz-js": () => import("./../../../src/pages/pytaj-o-co-chcesz.js" /* webpackChunkName: "component---src-pages-pytaj-o-co-chcesz-js" */),
  "component---src-pages-sprawdz-termin-js": () => import("./../../../src/pages/sprawdz-termin.js" /* webpackChunkName: "component---src-pages-sprawdz-termin-js" */),
  "component---src-pages-znizki-ciekawostki-terminy-js": () => import("./../../../src/pages/znizki-ciekawostki-terminy.js" /* webpackChunkName: "component---src-pages-znizki-ciekawostki-terminy-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-place-archive-js": () => import("./../../../src/templates/placeArchive.js" /* webpackChunkName: "component---src-templates-place-archive-js" */),
  "component---src-templates-post-archive-js": () => import("./../../../src/templates/postArchive.js" /* webpackChunkName: "component---src-templates-post-archive-js" */),
  "component---src-templates-single-house-js": () => import("./../../../src/templates/singleHouse.js" /* webpackChunkName: "component---src-templates-single-house-js" */),
  "component---src-templates-single-place-js": () => import("./../../../src/templates/singlePlace.js" /* webpackChunkName: "component---src-templates-single-place-js" */),
  "component---src-templates-single-post-js": () => import("./../../../src/templates/singlePost.js" /* webpackChunkName: "component---src-templates-single-post-js" */)
}

